import { createContainer } from '@blue-agency/front-state-management'
import { ParticipantsServiceContainer } from '@/containers/ParticipantsServiceContainer'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { storageKey } from '@/constants/storageKey'
import { VerifyEmailResponse } from '@blue-agency/proton/web/v2/yashiori_bff/yashiori_bff_service_pb'

const useVerifyEmail = () => {
  const { verifyEmail } = ParticipantsServiceContainer.useContainer()
  const [showNotFound, setShowNotFound] = useState(false)
  const history = useHistory()

  const { token } = useParams<{ token?: string }>()
  if (!token) throw new Error('token not found')

  useEffect(() => {
    ;(async () => {
      if (!token) {
        setShowNotFound(true)
        return
      }
      let res: VerifyEmailResponse
      try {
        res = await verifyEmail({ token })
        if (res.getHarutakaRedirection()) {
          window.location.href = `${process.env.REACT_APP_HARUTAKA_SEMINAR_FRONT_HOST}${window.location.pathname}`
        }
      } catch (e) {
        setShowNotFound(true)
        throw e
      }
      if (!res.getValidity()) {
        setShowNotFound(true)
        return
      }
      sessionStorage.setItem(storageKey.session.emailToken, token)
      history.replace(
        fillParams({
          path: INTERNAL_PATHS.participant.halls.entrance.room,
          params: { entranceGuid: res.getParticipantsEntranceGuid() },
        })
      )
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { showNotFound }
}

export const VerifyEmailContainer = createContainer(useVerifyEmail)
