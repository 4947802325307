import React, { useMemo } from 'react'
import { HallLayout } from '@/components/HallLayout'
import { SpHallLayout } from '@/components/SpHallLayout'
import { useBeforeUnload } from '@blue-agency/react-utils'
import { provide } from '@blue-agency/front-state-management'
import { Presenter } from '@/components/Presenter'
import { ScreenShare } from '@/components/ScreenShare'
import { Messages } from './Messages'
import { PresenterVideoContainer } from './containers/PresenterVideoContainer'
import { CheckContainer } from './containers/CheckContainer'
import { Redirect, useParams } from 'react-router-dom'
import { INTERNAL_PATHS, fillParams } from '@/services/urlService'
import { Pending } from './Pending'
import { LeftNode } from './LeftNode'
import { MessagesContainer } from './containers/MessagesContainer'
import { StartModal } from './StartModal'
import { Layout } from '@/components/Layout'
import { headerHeight } from '@/components/Header'
import { storageKey } from '@/constants/storageKey'
import { isPc } from '@/utils'
import { PreventSleep } from '@blue-agency/rogue'
import { HelpModalContainer } from './containers/HelpModalContainer'
import { HelpModal } from './HelpModal'
import { RightNode } from './RightNode'
import { useTitle } from '@/hooks/useTitle'
import { AlertboxHeightContainer } from '@/containers/AlertboxHeightContainer'
import { WindowSizeContainer } from '@/containers/WindowSizeContainer'

export const RoomPage: React.FCX = ({ className }) => {
  const token = sessionStorage.getItem(storageKey.session.emailToken)
  const { entranceGuid } = useParams<{ entranceGuid?: string }>()
  if (!entranceGuid) throw new Error('entranceGuid not found')
  if (!token)
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.participant.halls.entrance.newParticipant,
          params: { entranceGuid },
        })}
      />
    )
  return <Provided className={className} />
}

const Provided: React.FCX = provide(CheckContainer)(() => {
  const { status, entranceGuid, verifyEmailRes, checkEntranceRes } =
    CheckContainer.useContainer()

  const title = `${verifyEmailRes.getOrganizerName()} ${verifyEmailRes.getSessionName()}`
  useTitle(title)

  if (verifyEmailRes.getHarutakaRedirection()) {
    window.location.href = `${process.env.REACT_APP_HARUTAKA_SEMINAR_FRONT_HOST}${window.location.pathname}`
  }

  if (status === 'invalidToken')
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.participant.halls.entrance.newParticipant,
          params: { entranceGuid },
        })}
      />
    )
  if (status === 'finished')
    return (
      <Redirect
        to={fillParams({
          path: INTERNAL_PATHS.participant.halls.entrance.finish,
          params: { entranceGuid },
        })}
      />
    )
  if (status === 'pending')
    return (
      <Pending
        sessionName={verifyEmailRes.getSessionName()}
        organizerName={verifyEmailRes.getOrganizerName()}
        scheduledAt={checkEntranceRes.scheduledAt}
      />
    )
  return <VideoProvided />
})

const VideoProvided: React.FCX = provide(
  HelpModalContainer,
  MessagesContainer,
  PresenterVideoContainer
)(({ className }) => {
  const { isScreenShared, verifyEmailRes } = CheckContainer.useContainer()
  const {
    presenterVideoRef,
    screenShareRef,
    handleStart,
    showStartModal,
    enableAudio,
  } = PresenterVideoContainer.useContainer()
  const { handleHelpModalOpen } = HelpModalContainer.useContainer()
  const { width, height } = WindowSizeContainer.useContainer()
  const alertboxHeight = AlertboxHeightContainer.useContainer()

  const hallHeight = useMemo(
    () => height - headerHeight - alertboxHeight,
    [height, alertboxHeight]
  )
  useBeforeUnload('ページを離れます。よろしいですか？')

  return (
    <>
      {isPc(width) ? (
        <Layout
          className={className}
          leftNode={<LeftNode sessionName={verifyEmailRes.getSessionName()} />}
          rightNode={
            <RightNode
              organizerName={verifyEmailRes.getOrganizerName()}
              handleHelpModalOpen={handleHelpModalOpen}
            />
          }
        >
          <HallLayout
            height={hallHeight}
            width={width}
            presenterVideo={
              <Presenter muted={!enableAudio} ref={presenterVideoRef} />
            }
            screenShareVideo={<ScreenShare ref={screenShareRef} />}
            messages={<Messages />}
            screenSharing={isScreenShared}
            className={className}
          />
        </Layout>
      ) : (
        <>
          <SpHallLayout
            presenterVideo={
              <Presenter
                controls
                muted={!enableAudio}
                ref={presenterVideoRef}
              />
            }
            screenShareVideo={<ScreenShare controls ref={screenShareRef} />}
            messages={<Messages />}
            onQuestionIconClick={handleHelpModalOpen}
            screenSharing={isScreenShared}
            className={className}
          />
          <PreventSleep />
        </>
      )}
      {showStartModal && <StartModal onClick={handleStart} />}
      <HelpModal />
    </>
  )
})
